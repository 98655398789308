import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";

export const Career = () => {
    return (
        <section>
            {/* <Container className="career" id="career">
                <Col size={12} md={6}>

                    <h2>Roles</h2>
                    <Row>
                        <Col size={12} sm={6} className="px-1">
                            <input type="text" placeholder="First Name" />
                        </Col>
                        <Col size={12} sm={6} className="px-1">
                            <input type="text" placeholder="Last Name" />
                        </Col>
                        <Col size={12} sm={6} className="px-1">
                            <input type="email" placeholder="Email Address" />
                        </Col>
                        <Col size={12} sm={6} className="px-1">
                            <input type="tel" placeholder="Phone No." />
                        </Col>
                    </Row>
                </Col>

                <Col size={12} md={6}>
                    <img src={contactImg} alt="Career" />
                </Col>
            </Container> */}


        </section>
    )
}
import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { Newsletter } from "./Newsletter";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Newsletter />
          <Col size={12} sm={6}>
            <div>
              <img src={logo} alt="Logo" />
              <p></p>
            </div>
            <h5>Email: contact@rothurtech.com</h5>
            <h5>Phone: +1 (631) 268-5674</h5>
            <h5>Office: 800 Third Avenue FRNT A Suite 1563 New York, NY 10022</h5>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href='https://www.linkedin.com/company/rothur-tech/'><img src={navIcon1} alt="Icon" /></a>
              <a href="#"><img src={navIcon2} alt="Icon" /></a>
              <a href="#"><img src={navIcon3} alt="Icon" /></a>
            </div>
            <p>Copyright © 2023 Rothur Tech all rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

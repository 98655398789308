import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {

  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    file: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");

    // Use FormData for sending files
    const formData = new FormData();
    formData.append('firstName', formDetails.firstName);
    formData.append('lastName', formDetails.lastName);
    formData.append('email', formDetails.email);
    formData.append('phone', formDetails.phone);
    formData.append('message', formDetails.message);
    formData.append('file', formDetails.file);

    let response = await fetch("https://www.rothurtech.com/contact", {
      method: "POST",
      body: formData, // Send formData instead of a JSON object
    });

    setButtonText("Send");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code === 200) {
      setStatus({ success: true, message: 'Message sent successfully' });
    } else {
      setStatus({ success: false, message: 'Something went wrong, please try again later.' });
    }
  };


  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>We Are Hiring</h2>
                  <h4>Software Engineer, Data Engineer, Web Developer, Cloud Engineer, Machine Learning Engineer, UI/UX Designer, Mobile Engineer.</h4>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
                      </Col>
                    
                      <Col size={12} sm={12} className="px-1">
                        <label htmlFor="fileUpload" className={`file-input-label ${formDetails.file ? "file-selected" : ""}`}>
                          {formDetails.file ? "File Selected" : "Click to Choose File"}
                        </label>
                        <span className="file-name">
                          {formDetails.file ? formDetails.file.name : ''}
                        </span>
                        <input
                          id="fileUpload"
                          type='file'
                          onChange={(e) => { onFormUpdate('file', e.target.files[0]) }}
                        />
                      </Col>


                      <Col size={12} className="px-1">
                        <textarea rows="6" value={formDetails.message} placeholder="Tell us about you!" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                        <button type="submit"><span>{buttonText}</span></button>
                      </Col>

                      {
                        status.message &&
                        <Col>
                          <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                        </Col>
                      }
                    </Row>
                  </form>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
